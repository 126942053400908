<template>
  <section id="area-screen" class="home-section-1 scrolled">
    <div id="user-area" class="home-section-1-inner-wrapper">
      <div class="home-section-1-bg">
        <div id="user-area-inner-form">
          <div id="user-area-close"><i class="fas fa-times"></i></div>
          <div id="form-inner" v-if="this.user_name">
            <div class="diagonal-form pointer">
              <!--  @click="irAreaCliente() -->
              Área de Cliente
            </div>
            <span id="log-out" @click="logOut()">
              LOGOUT <i class="fa fa-sign-out-alt" aria-hidden="true"></i>
            </span>
            <div class="title-with-strap white h1">
              Olá {{ this.user_name }}
            </div>
            <div id="form-fields">
              <div class="row">
                <div class="field">
                  <i class="fa fa-user"></i>Nome de Utilizador
                </div>
                <input
                  id="novo-nome"
                  type="text"
                  placeholder="Nome de Utilizador"
                  class="novo-user-form"
                />
              </div>
              <div class="row">
                <div class="field">
                  <i class="fa fa-envelope" aria-hidden="true"></i>E-mail
                </div>
                <input
                  id="novo-email"
                  type="text"
                  placeholder="E-mail"
                  class="novo-user-form"
                />
              </div>
              <div class="row">
                <div class="field">
                  <i class="fa fa-lock"></i>Nova palavra-passe
                </div>
                <input
                  id="novo-password"
                  type="password"
                  placeholder="Nova palavra-passe"
                  class="novo-user-form"
                />
              </div>
              <div class="row">
                <div class="field">
                  <i class="fa fa-lock"></i>Repetir palavra-passe
                </div>
                <input
                  id="novo-password-2"
                  type="password"
                  placeholder="Repetir palavra-passe"
                  class="novo-user-form"
                />
              </div>
              <div class="buttons">
                <!-- <a id="botao-download" class="button ghost diagonal white">Minuta de Contrato <b>ONE</b></a> -->
                <a
                  id="botao-actualizar"
                  class="button ghost diagonal white"
                  @click="updateUser()"
                  >Confirmar</a
                >
              </div>
              <div
                id="resultado-update"
                class="row"
                style="text-align: center"
              ></div>
            </div>
          </div>
          <div id="form-inner" v-else>
            <div class="elemento-center">
              <div class="title-with-strap white h1 text">
                Área de Cliente - Login
              </div>
              <form class="form-login">
                <div class="form-linha">
                  <div
                    class="input-item item-width-100 input-com-icone user-icone"
                  >
                    <input
                      id="username"
                      type="text"
                      value=""
                      placeholder="Nome de utilizador"
                      name="NomeUtilizador"
                      class="more-padding-top-bottom"
                    />
                  </div>
                </div>
                <div class="form-linha">
                  <div
                    class="input-item item-width-100 input-com-icone password-icone"
                  >
                    <input
                      id="password"
                      type="password"
                      value=""
                      placeholder="Palavra-passe"
                      name="Palavra-passe"
                      class="more-padding-top-bottom"
                    />
                  </div>
                </div>
                <div class="links-extra">
                  <div class="link-item">
                    <input
                      type="checkbox"
                      v-model="checked"
                      name="Lembrar"
                      id="checkbox"
                      class="custom-checkbox"
                    /><label for="checkbox">Lembrar-se de mim</label>
                  </div>
                  <!-- <span class="separador"> / </span> -->
                  <div class="link-item">
                    <router-link to="/reset-password" class="simple-link"
                      >Esqueceu-se da sua palavra-passe?</router-link
                    >
                  </div>
                </div>

                <div id="logiResult"></div>

                <div class="button-wrapper">
                  <button
                    id="button-submit"
                    class="button diagonal font-weight-500 text-uppercase"
                    @click="loginForm()"
                  >
                    Iniciar sessão
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>

<script>
  export default {
    name: "AreaUser",
    data() {
      return {
        user_id: "",
        name: "",
        email: "",
        username: "",
        user_level: "",
        checked: false,
        id: window.sessionStorage.getItem("user_id"),
        user_name: window.sessionStorage.getItem("name"),
      };
    },
    methods: {
      irAreaCliente() {
        this.$router.push("/area-privada");
      },
      updateUser: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        // for(var campo of campos) {
        //     console.info(campo.id + ": " + campo.value)
        // }

        var email = document.querySelector("#novo-email").value;
        var nome = document.querySelector("#novo-nome").value;
        var pass = document.querySelector("#novo-password").value;
        var pass2 = document.querySelector("#novo-password-2").value;

        var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (nome == "" || nome == null) {
          document.querySelector("#resultado-update").innerHTML =
            "O nome é um campo obrigatório";

          return false;
        } else if (email == "" || email == null) {
          document.querySelector("#resultado-update").innerHTML =
            "O email é um campo obrigatório";

          return false;
        } else if (!emailReg.test(email)) {
          document.querySelector("#resultado-update").innerHTML =
            "O email introduzido é inválido 2";

          return false;
        } else if (
          (pass != null && pass != "") ||
          (pass2 != null && pass2 != "")
        ) {
          if (pass != pass2) {
            document.querySelector("#resultado-update").innerHTML =
              "As duas passwords não correspondem";

            return false;
          }
        }

        document.querySelector("#resultado-update").innerHTML =
          "A actualizar perfil...";

        let apibody = {
          user_id: window.sessionStorage.user_id,
          name: nome,
          email,
          pass,
        };

        console.log("teste");

        const res = await this.updateUserAPI(apibody);

        if (res.resultado == "KO") {
          document.querySelector("#resultado-update").innerHTML =
            "Alguma coisa correu mal...<br>Por favor tente mais tarde";
        } else {
          window.sessionStorage.setItem("email", email);
          window.sessionStorage.setItem("name", nome);

          setTimeout(function () {
            window.location.href = window.location.href;
          }, 200);

          document.querySelector("#resultado-update").innerHTML =
            "O seu perfil foi actualizado com sucesso!";
        }
      },
      updateUserAPI: async function (apiBody) {
        try {
          var response = await fetch(
            "https://bydservices.pt/glintt-api/public/api/user/update",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(apiBody, null, 2),
            }
          );
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
      logOut: async function () {
        window.sessionStorage.clear();
        this.$cookies.remove("username");
        this.$cookies.remove("token");
        window.location.href = "/";
      },
      loginForm: function () {
        event.preventDefault();

        var username = document.querySelector("#username").value;
        var password = document.querySelector("#password").value;

        var dados = {
          username,
          password,
        };
        this.submitLogin(dados, "form");
      },
      submitLogin: async function (dados, origin) {
        var campoResposta = document.querySelector("#logiResult");
        campoResposta.innerHTML = "A validar...";

        var result = await this.doLogin(dados);

        if (result.resultado == "OK") {
          if (result.user_level == "0") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "Farmacias",
            });
            // Analytics pixel
            if ("ga" in window) {
              let tracker = ga.getAll()[0];
              if (tracker) {
                tracker.send("event", "Farmacias", "Farmacias", "Objetivo");
              }
            }
            // end of analytics pixel
          }

          this.user_id = result.userID;
          this.username = result.username;
          this.user_level = result.user_level;
          this.email = result.email;
          this.name = result.name;
          this.anf = result.anf;

          campoResposta.innerHTML = "Bem-vindo!";

          if (result.first_access == 1) {
            this.updateUserShow();
          } else {
            window.sessionStorage.setItem("user_id", this.user_id);
            window.sessionStorage.setItem("username", this.username);
            window.sessionStorage.setItem("user_level", this.user_level);
            window.sessionStorage.setItem("email", this.email);
            window.sessionStorage.setItem("name", this.name);
            window.sessionStorage.setItem("anf", this.anf);

            if (this.checked && origin == "form") {
              var hash = CryptoJS.AES.encrypt(
                `${dados.password}`,
                "%SW91@0[wXLb8Zt"
              ).toString();

              this.$cookies.set("username", `${this.username}`, "30d");
              this.$cookies.set("token", `${hash}`, "30d");
            }

            let redirectPath = window.sessionStorage.getItem("redirectPath");
            let router = this.$router.go(-1);

            setTimeout(function () {
              router.push(redirectPath || "/home");
              // window.location.href = redirectPath || "/home"
            }, 500);

            window.sessionStorage.removeItem("redirectPath");
            location.reload();
          }
        } else {
          if (origin == "cookies") {
            this.$cookies.remove("username");
            this.$cookies.remove("token");
            campoResposta.innerHTML = "";
          } else {
            campoResposta.innerHTML = "Utilizador ou password errados";
          }
        }

        // displayResult.innerHTML = result.resultado
      },
      updateUserShow: async function () {
        var campos = document.querySelectorAll(".novo-user-form");
        for (var campo of campos) {
          campo.value = null;
        }

        document.querySelector("#novo-nome").value = this.name;
        document.querySelector("#novo-email").value = this.email;

        document.querySelector("#area-screen").className = "home-section-1";

        document.querySelector(".form-login").innerHTML =
          "<i class='fa fa-check' style='font-size:80px; color: var(--cor-principal)'></i><br>Login realizado com sucesso!";
      },
      doLogin: async function (apiBody) {
        try {
          var response = await fetch(
            "https://bydservices.pt/glintt-api/public/api/login",
            {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(apiBody, null, 2),
            }
          );
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
    mounted() {
      console.log(this.id);
      $(document).ready(function () {
        $("#user-area-close").click(function () {
          $(".home-section-1").addClass("scrolled");
          setTimeout(function () {
            if (this.$route.name == "home") {
              if (document.querySelector("#icon-user-header") != null) {
                document.querySelector("#icon-user-header").style =
                  "color: #000 !important;";
              }
              if (document.querySelector("#icon-user-header-home") != null) {
                document.querySelector("#icon-user-header-home").style =
                  "color: #000000;";
              }
              if (document.querySelector("#icon-user-header-area") != null) {
                document.querySelector("#icon-user-header-area").style =
                  "color: #000;";
              }
            } else {
              if (document.querySelector("#icon-user-header") != null) {
                document.querySelector("#icon-user-header").style =
                  "color: #FFFFFF !important;";
              }
              if (document.querySelector("#icon-user-header-home") != null) {
                document.querySelector("#icon-user-header-home").style =
                  "color: #000000;";
              }
              if (document.querySelector("#icon-user-header-area") != null) {
                document.querySelector("#icon-user-header-area").style =
                  "color: #ffffff;";
              }
            }
          }, 500);
          setTimeout(function () {
            document.querySelector("#area-screen").style.display = "none";
          }, 1500);
        });
      });
    },
    updated() {
      let isLogged = window.sessionStorage.getItem("user_id");

      if (isLogged != null) {
        window.location.href = "/home";
      }
    },
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  @media only screen and (min-width: 1024px) and (orientation: landscape) {
    #user-area {
      left: 50vw;
      width: 50vw;
    }
    #user-area-inner-form {
      width: 50vw;
      left: 0px;
      height: calc(100%);
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
    #user-area #user-area-inner-form #user-area-close {
      margin-left: auto;
      color: #ffffff;
      padding: 15px;
      height: 15px;
      z-index: 999;
      font-size: 20px;
      padding-right: 20px;
    }
    #user-area-close:hover {
      cursor: pointer;
    }
    #form-inner {
      color: #ffffff;
      padding: 15px;
      height: fit-content;
      width: 100%;
      display: flex;
      align-self: center;
      flex-direction: column;
      font-family: "Calibri", sans-serif;
      letter-spacing: 0.5px;
      font-size: 16px;
    }
    #form-inner div:not(.buttons) {
      margin: 10px;
      color: #ffffff;
    }
    .diagonal-form {
      padding: 0.8rem 2rem;
      font-size: 16px;
      font-weight: 600;
      color: #000000 !important;
      display: inline-block;
      position: relative;
      z-index: 1;
      text-decoration: none;
      border: none;
      width: fit-content;
    }
    .diagonal-form::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      transform: skew(-15deg);
      -webkit-transform: skew(-15deg);
      display: inline-block;
      z-index: -1;
    }

    #log-out {
      display: flex;
      align-items: center;
      position: fixed;
      bottom: 15px;
      right: 15px;
      font-size: 80%;
      color: #fff;
    }
    #log-out i {
      margin-left: 5px;
    }
    #log-out:hover {
      cursor: pointer;
      font-weight: bold;
    }
    #form-fields {
      color: #ffffff;
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0px;
    }
    .row {
      display: flex;
      align-self: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
      width: 100%;
      margin-bottom: 20px;
      font-size: 16px;
    }
    .row div {
      align-self: flex-start;
      min-width: 30%;
    }
    .field {
      min-width: 45% !important;
    }
    .field i {
      margin-right: 10px;
      min-width: 20px;
    }
    .row input {
      display: inline-block;
      width: 40% !important;
      border: none;
      background-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-bottom: 1px solid #ffffff;
      color: #000;
      padding: 2px 0 !important;
      font-family: "Calibri", sans-serif;
      font-size: 15px;
      font-weight: 600 !important;
      border-radius: 0;
      -webkit-appearance: none;
    }
    .row input::placeholder {
      color: #545454;
      font-weight: 600;
    }
    .buttons {
      display: flex;
      align-self: left;
      flex-direction: row;
      flex-wrap: wrap;
      width: 88%;
      margin: 40px 0 20px 0;
      font-size: 16px;
      justify-content: flex-end;
    }
    #botao-actualizar {
      width: fit-content;
    }
    #botao-actualizar:hover {
      cursor: pointer;
      font-weight: bold;
    }
    #botao-download {
      color: #ea8822f2;
      margin-right: 20px;
    }
    #botao-download::before {
      background-color: #ffffff;
    }
    #botao-download:hover {
      cursor: pointer;
    }
    .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg {
      width: 100vw;
      height: calc(100vh - 13vh);
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--cor-2);
      background-color: #ea8822f2;
      margin-top: calc(50px + 14px + 6.75vh);
      margin-bottom: auto;
    }
    .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
      -moz-transform: scaleY(-1);
      -o-transform: scaleY(-1);
      -webkit-transform: scaleY(-1);
      transform: scaleY(-1);
      filter: FlipV;
      -ms-filter: "FlipV";
      left: calc(-20%) - 1px;
      opacity: 0.95;
    }
    .home-section-1 {
      z-index: 1001;
      position: fixed;
      overflow-y: hidden;
      display: none;
    }
  }

  @media only screen and (max-width: 640px) {
    .home-section-1 .home-section-1-inner-wrapper .home-section-1-bg:before {
      display: none;
    }
    #seccao-servicos #user-area .home-section-1-bg {
      height: 100vh;
      width: 100vw;
      top: 0;
    }
    #form-inner {
      position: absolute;
      width: calc(100vw - 30px);
    }
    #log-out {
      position: fixed;
      bottom: 15px;
      right: 15px;
      color: #fff;
    }
    #user-area {
      position: fixed;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      z-index: 999;
    }
    #user-area-inner-form {
      width: 100vw;
      height: 100vh;
    }
    #form-fields {
      width: calc(100% - 20px);
    }
    .row {
      flex-direction: column;
      width: 90%;
    }
    .field {
      min-width: 100% !important;
      margin-left: 0px !important;
    }
    .row input {
      width: 100% !important;
    }
    /* #botao-actualizar {
            margin-left: 0;
            align-self: flex-end;
        } */
  }
</style>
